import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const ProtectedRoute = ({ children }) => {
  const token = sessionStorage.getItem('token') // Check if the user is logged in

  if (!token) {
    return <Navigate to="/" /> // Redirect to LandingPage if not logged in
  }

  return children // Render the child components if authenticated
}

// Add prop validation
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ProtectedRoute