import React, { Suspense } from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'
import './scss/style.scss'
import ProtectedRoute from './ProtectedRoute'

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const PaymentForm = React.lazy(() => import('./views/pages/landingForm/PaymentForm'))
const LandingPage = React.lazy(() => import('./views/pages/landingForm/LandingPage'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const App = () => {
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/payment" element={<PaymentForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/singupgearspay" element={<Register />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
          
          {/* Protected Routes */}
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <DefaultLayout />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App